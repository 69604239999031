/* eslint-disable react/prop-types */
import * as React from 'react';

import styled from 'styled-components';

import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';
import { H2 } from '../Layout/SectionTypography';

// import IconTriangle from './icons/IconTriangle';

const Main = styled.h2`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: #ffffff;
  margin-bottom: 24px;
  .bubble {
    display: block;
    font-size: 20px;
    font-weight: 600;
    .bubble-wrapper {
      position: relative;
      display: inline-block;
    }
    .bubble-text {
      border: 1px solid #242938;
      border-radius: 8px;
      padding: 4px 12px;
      color: #242938;

      line-height: 28px;
      letter-spacing: 0px;
    }
    .bubble-subtext {
      color: #242938;
      display: inline;
    }

    > div {
      &:first-child {
        margin-right: 8px;
      }
    }
  }
  .triangle {
    font-size: 16px;
    line-height: 0;
    position: relative;
    top: -2px;
    color: #242938;
  }

  @media ${deviceBreakpoints.lg} {
    .bubble {
      font-size: 24px;
      line-height: 32px;
      .bubble-text {
        line-height: 32px;
      }
      > div {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const BubbleTitle = ({ data, ...rest }) => {
  return (
    <Main className="section-title" {...rest}>
      <div className="bubble">
        {data.map((item, index) => {
          const { type, text, bgColor } = item;
          if (type === 'bubble-disabled') {
            // disabled bubble for now
            return (
              <div className="bubble-wrapper">
                <div
                  style={{ backgroundColor: bgColor }}
                  key={index}
                  className="bubble-text"
                >
                  {text}
                </div>
              </div>
            );
          }
          return (
            <H2 key={index} as="span">
              {text}
              {index < data.length - 1 ? ' ' : null}
            </H2>
          );
        })}
      </div>
    </Main>
  );
};

export default BubbleTitle;
