import * as React from 'react';
import MainLayout from '../components/Layout/MainLayout';
import MySEO from '../shared/MySEO';
import styled from 'styled-components';
import { DolTechnologyHeader } from '../shared/DolTechnology/DolTechnologyHeader';
import { CTAForScheduleAndRegister } from '../shared/DolBigCTA/cta-schedule-register';
import { withMarketingSection } from '../components/withMarketingSection';
import { SeparationSectionLayout } from '../shared/Layout/SectionLayout';
import { LineSeparator } from '../shared/Layout/LineSeparator';
import { Typography } from '../components/DesignSystemV2/Typography';
import { getAssetLinkByHandle } from '../utils/getAssetLink';
import ProgressiveImage from 'react-progressive-image';
import { css } from 'styled-components/macro';
import { fromScreen } from '../utils/media-query/responsive.util';
import IconCheck from '../shared/CourseLanding/Detail/icons/IconCheck';
import { XCircle } from '../components/Icon/SVGIcons';
import { colorsV2 } from '../style/colors-v2';
import BubbleTitle from '../shared/SchedulePage/BubbleTitle';
import { OutlineSchoolIcPresent } from '@dolstaff/shared/es/icons';
import { AnimatedVideo } from '../shared/VideoThumbnailInline/AnimatedVideo';

const Wrapper = styled.div``;

const technologyData = [
  {
    img: 'wxHQc3kOTzCidmwL6iiM',
    video: 'DWn6KPJQUOulaABJkWCg',
    content: {
      icon: 'ADZL4AnRhW6UNx4oC2ng',
      title: 'IELTS Online test',
      description:
        'Làm Reading, Listening giao diện thi thật, xem đáp án, giải thích chi tiết và học từ vựng.'
    }
  },
  {
    img: 'MBxpFy5cTA6m0wQLkjIs',
    video: 'lQAOLyqSvSqHfycWYywU',
    content: {
      icon: 'bjLw0hLSS4CmrGAImkda',
      title: 'Kho bài mẫu Writing/ Speaking',
      description:
        'Bài mẫu 8.0+ cập nhật cùng dàn ý, từ vựng và bài tập ôn lại đi kèm.'
    }
  },
  {
    img: 'Z2nzZ6CXQEcwuQtJOQGG',
    video: 'SYvfscjvSgy9hOfdHHX1',
    content: {
      icon: 'V2uJY6AtRxuFSCfux1bh',
      title: 'Chép chính tả',
      description:
        'Chép chính tả theo từng câu, từng từ. Có thể chọn chủ đề và độ khó tùy trình độ.'
    }
  },
  {
    img: 'H3wZKTtgSiaeuTyB4Z78',
    video: '4lWHX0doT3eUIBNfeMqg',
    content: {
      icon: 'rt4Wa3iCSQ6F32ShcVpc',
      title: 'Học Tiếng Anh qua tin tức',
      description:
        'Các tin tức hot ở Việt Nam và quốc tế được viết bằng Tiếng Anh kèm ví dụ.'
    }
  },
  {
    img: 'hqdnKNcR2aoFPLY7HJAU',
    video: 'P5UuM2WT0eAKJuycylbj',
    content: {
      icon: '9YufIYJSRIG1QGmwxeeD',
      title: 'Làm và nhận bài sửa assignment',
      description:
        'Làm bài Writing/ Speaking và nhận bài sửa chi tiết của giáo viên kèm phần nhận xét bằng “thu âm”. Bạn sẽ có cảm giác như giáo viên đang giảng bài riêng cho bạn.'
    }
  },
  {
    img: 'IWiEx63VSA6RYCuOWmTf',
    video: 'SgjciiEcQVKEa8XAUYyp',
    content: {
      icon: '5faCL5TfW0Nk83HNn9QQ',
      title: 'Trợ lý Speaking ở nhà',
      description:
        'DOL thiết kế rất nhiều kịch bản nói và dùng công nghệ speech recognition để kích thích việc luyện tập của học viên ở nhà.'
    }
  },
  {
    img: 'YRQUfGQkRFaWpNxn1fFM',
    video: 'VJqYg3NVRcmdRbGTiwp4',
    content: {
      icon: 'c9ox7Xk5RoyGzuSeFKcB',
      title: 'Exercise như giáo viên ở nhà',
      description:
        'DOL thiết kế exercise thành một câu chuyện để học viên thấy hứng thú khi học và để tạo trải nghiệm như đang có 1 giáo viên bên cạnh cùng làm bài với học viên.'
    }
  }
];

const ContentText = styled(Typography).attrs({
  variant: 'medium/18',
  color: colorsV2.black80
})``;
const HeaderText = styled(Typography).attrs({
  variant: 'semi-bold/20'
})``;

const TableCell = styled.td`
  padding: 16px 12px;
`;
const TableRow = styled.tr`
  svg {
    width: 20px;
    height: 20px;
  }
  > *:nth-child(2) {
    background-color: ${colorsV2.blue10};
  }
`;
const TableHeader = styled(TableRow)``;
const ComparisonTableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
  ${TableRow}:not(:last-child) {
    border-bottom: 1px solid ${colorsV2.gray100};
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1em;
    height: 1em;
    margin-right: 8px;
    display: none;
  }
  ${fromScreen(768)} {
    svg {
      display: inline-block;
    }
  }
`;
const ComparisonTable = () => {
  return (
    <>
      <BubbleTitle
        data={[
          {
            type: 'text',
            text: (
              <SectionHeader>
                <OutlineSchoolIcPresent size={48} />
                Quyền lợi của học viên tại DOL
              </SectionHeader>
            )
          }
        ]}
      />
      <ComparisonTableWrapper>
        <TableHeader>
          <TableCell>
            <HeaderText>Tính năng</HeaderText>
          </TableCell>
          <TableCell>
            <HeaderText>Học viên DOL</HeaderText>
          </TableCell>
          <TableCell>
            <HeaderText>Người dùng</HeaderText>
          </TableCell>
        </TableHeader>
        <TableRow>
          <TableCell>
            <ContentText>IELTS Online test</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Kho bài mẫu Writing/ Speaking</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Chép chính tả</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Học Tiếng Anh qua tin tức</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Làm và nhận bài sửa assignment</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <XCircle />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Trợ lý Speaking ở nhà</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <XCircle />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Exercise như giáo viên ở nhà</ContentText>
          </TableCell>
          <TableCell>
            <IconCheck />
          </TableCell>
          <TableCell>
            <XCircle />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <ContentText>Giá 1 năm</ContentText>
          </TableCell>
          <TableCell>
            <ContentText>Miễn phí</ContentText>
          </TableCell>
          <TableCell>
            <ContentText>4.990k /năm</ContentText>
          </TableCell>
        </TableRow>
      </ComparisonTableWrapper>
    </>
  );
};

const Info = styled.div`
  display: grid;
  grid-row-gap: 12px;
  grid-auto-flow: row;
  align-self: center;

  img {
    display: none;
  }

  ${fromScreen(768)} {
    img {
      display: block;
    }
  }
`;
const Section = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;

  ${fromScreen(768)} {
    grid-template-columns: 1fr 250px;
    grid-column-gap: 48px;

    > img {
      grid-area: image;
    }

    > div {
      grid-area: info;
    }

    grid-template-areas: 'image info';
    ${props =>
      props.reverse &&
      css`
        grid-template-areas: 'info image';
        grid-template-columns: 250px 1fr;
      `}
  }

  ${fromScreen(1366)} {
    grid-template-columns: 1fr 343px;
    ${props =>
      props.reverse &&
      css`
        grid-template-columns: 343px 1fr;
      `}
  }
`;
const TechnologySectionWrapper = styled.div``;
const TechnologySection = () => {
  return (
    <TechnologySectionWrapper>
      <SeparationSectionLayout
        Separator={LineSeparator}
        lastChildHasSeparator={false}
      >
        {technologyData.map(
          ({ img, video, content: { icon, title, description } }, index) => (
            <Section key={index} reverse={index % 2 === 1}>
              {/*<ProgressiveImage*/}
              {/*  src={getAssetLinkByHandle({ handle: img })}*/}
              {/*  placeholder={getAssetLinkByHandle({ handle: img, width: 10 })}*/}
              {/*>*/}
              {/*  {src => (*/}
              {/*    <img className="sub-banner" src={src} alt="technology" />*/}
              {/*  )}*/}
              {/*</ProgressiveImage>*/}
              <AnimatedVideo imageHandle={{ handle: video }} />
              <Info>
                <img
                  src={getAssetLinkByHandle({ handle: icon })}
                  alt="nền tảng công nghệ"
                />
                <Typography variant="bold/24-32" desktopVariant="bold/32-40">
                  {title}
                </Typography>
                <Typography variant="regular/18" desktopVariant="regular/20">
                  {description}
                </Typography>
              </Info>
            </Section>
          )
        )}
      </SeparationSectionLayout>
    </TechnologySectionWrapper>
  );
};

const ContentSection = withMarketingSection(
  () => {
    return (
      <SeparationSectionLayout lastChildHasSeparator={true}>
        <TechnologySection />
        <ComparisonTable />
      </SeparationSectionLayout>
    );
  },
  {
    style: {
      paddingTop: 32,
      paddingBottom: 24
    },
    selfish: true
  }
);

const TechnologyPage = ({ location }) => {
  return (
    <MainLayout showFooterRegister hideSideCTA>
      <MySEO
        slug={location.pathname}
        h1="Nền tảng công nghệ tại DOL"
        hidePostfix
        title="Nền tảng công nghệ - DOL ENGLISH"
        description="Nền tảng công nghệ độc quyền tại DOL English giúp số hóa các loại nội dung với hình thức sáng tạo, hấp dẫn; trải nghiệm vượt trội dành riêng cho học viên."
      />
      <Wrapper>
        <DolTechnologyHeader />
        <CTAForScheduleAndRegister />

        <ContentSection />
      </Wrapper>
    </MainLayout>
  );
};

export default TechnologyPage;
